import axios from "axios";
import { api } from "./index";

export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const getStudents = () => {
  return api.get(`/students`);
};
export const addStudents = (data) => {
  return api.post(`/students`, data);
};

export const getWhatsappQr = () => {
  return api.get(`/getqr`);
};

export const resetWhatsaapQr = () => {
  return api.get(`/resetqr`, { timeout: 1000 * 60 * 10 });
};

export const checkAuth = () => {
  return api.get(`/checkauth`);
};

export const sendMessages = (data) => {
  return api.post(`/messages-numbers`, data, { timeout: 1000 * 60 * 10 });
};

export const sendMessageWithImage = (data) => {
  return api.post(`/messages-numbers-with-image`, data, {
    timeout: 1000 * 60 * 10,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const createStudent = (data) => {
  return api.post(`/students`, data);
};

export const updateStudent = (id, data) => {
  return api.patch(`/students/${id}`, data);
};

export const deleteStudentAPI = (id) => {
  return api.delete(`/students/${id}`);
};

export const getThirdStudents = () => {
  return api.get(`/thirdStudents`);
};

export const getThirdStudentInfo = (id) => {
  return api.get(`/thirdStudents/${id}`);
};

export const updateStudentInfo = (id, data) => {
  return api.patch(`/thirdStudents/${id}`, data);
};

export const getAdminData = () => {
  return api.get(`/admin-data`);
};

export const getRestaurants = () => {
  return api.get(`/admin-restaurants`);
};
export const getUsers = () => {
  return api.get(`/admin-users`);
};
export const getIntegrations = () => {
  return api.get(`/admin-integrations`);
};
export const getRefers = () => {
  return api.get(`/admin-refers`);
};

export const patchRefer = (id, data) => {
  return api.patch(`/refers/${id}`, data);
};

export const postRefer = (data) => {
  return api.post(`/refers`, data);
};

// export const sendMessage = (data) => {
//   return api.post(`/send-message`, data);
// };

export const sendEmails = (data) => {
  return api.post(`/send-emails`, data);
};

export const getPdfReport = (data) => {
  return api.post(`/pdf-report`, data, { responseType: "blob" });
};
export const sendMessagesWithReport = (data) => {
  return api.post(`/messages-numbers-with-report`, data, {
    timeout: 1000 * 60 * 10,
  });
};

export const createBehavioralReport = (data) => {
  return api.post(`/behavioral-report`, data, { responseType: "blob" });
};

export const behavioralById = (id, studentId) => {
  return api.delete(`/behavioral/${id}?studentId=${studentId}`);
};

export const getOneStatregy = (id) => {
  return api.get(`/strategy/${id}`);
};

export const addNewStartegy = (data) => {
  return api.post(`/strategy`, data);
};

export const getStateygFiles = (id) => {
  return api.get(`/strategyPDF/${id}`, { responseType: "blob" });
};
