import {
  Col,
  Divider,
  Image,
  Layout,
  message,
  Row,
  Spin,
  Tag,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import LOGO from "../../assets/images/logoNew.png";
import { useParams } from "react-router-dom";
import { getOneStatregy, getStateygFiles } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import apiUrl from "../../API/config";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomButton from "../../Components/CustomButton";

const { Content } = Layout;
const { Title, Text } = Typography;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];
const { Dragger } = Upload;
function OneStrategies() {
  const { id } = useParams();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [strategy, setStrategy] = useState(null);
  const [images, setImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [errorSizeCover, setErrorSizeCover] = useState(false);
  const [errorSizeImage, setErrorSizeImage] = useState(false);
  useEffect(() => {
    getOneStatregyL();
  }, []);
  const getOneStatregyL = async () => {
    try {
      setLoading(true);
      const res = await getOneStatregy(id);
      setStrategy(res.data?.strategy);
      if (res.data?.strategy?.images) {
        setImages(
          res.data?.strategy?.images?.map((url, index) => {
            return {
              uid: url,
              name: index + 1,
              status: "done",
              url: `${url}`,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error("الحد الأعلى هو ٢ ميجا بايت");
        return false;
      }
    }

    return true;
  };
  // handle image upload;
  const handleChangeImages = ({ fileList, file }, type) => {
    let newFileList = [...fileList];
    if (newFileList.length > 3) {
      return message.error("الحد الأقصى للصور هو ٤");
    }
    setErrorSizeImage(false);
    let errors = [];
    newFileList.forEach((el) => {
      if (el.size > 2000000) {
        //check if the image size is bigger than 2Mo
        errors.push(el?.uid);
      }
    });
    if (errors.length > 0) {
      //return error if there is images bigger than 2Mo

      setErrorSizeImage(true);

      return;
    }
    if (file.status === "uploading") {
      setLoadingImage(true); // show loading;
      setLoading(true);
    } else if (file.status === "done") {
      // upload success;
      setLoadingImage(false); // hide loading;
      setLoading(false);
      console.log(file.response);
    } else if (file.status === "error") {
      setLoadingImage(false); // hide loading;
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl: true,
      });
    }

    setImages([...newFileList]);
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Content
        style={{
          flex: 2,
          display: "flex",

          flexDirection: "column",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image preview={false} width={220} src={LOGO} />
        </div>
        <Title
          style={{
            textAlign: "center",
          }}
          level={2}
        >
          {" "}
          استراتيجيات التعلم
        </Title>
        <Divider />
        <Spin spinning={loading}>
          {/* display stategy info */}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Title level={5}>الاسم</Title>
              <Tag color='green'>
                <p>{strategy?.techearName}</p>
              </Tag>
            </Col>
            <Col span={12}>
              <Title level={5}>المادة</Title>
              <Tag color='green'>
                <p>{strategy?.subject}</p>
              </Tag>
            </Col>
            <Col span={12}>
              <Title level={5}>الاستراتيجية</Title>
              <Tag color='green'>
                <p>{strategy?.stategyName}</p>
              </Tag>
            </Col>
            <Col span={12}>
              <Title level={5}>العنوان</Title>
              <Tag color='green'>
                <p>{strategy?.title}</p>
              </Tag>
            </Col>
            <Col span={12}>
              <Title level={5}>الصف</Title>
              <Tag color='green'>
                <p>{strategy?.grade}</p>
              </Tag>
            </Col>
            <Col span={12}>
              <Title level={5}>التاريخ</Title>
              <Tag color='green'>
                <p>{dayjs(strategy?.date)?.format("DD/MM/YYYY")}</p>
              </Tag>
            </Col>
          </Row>
          <Divider />
          <div>
            <Dragger
              action={`${apiUrl}/strategy/${strategy?._id}`}
              name='picture'
              fileList={images}
              onRemove={(e) => {
                // deleteImage(e, "cover");
                //   updateShowCoverImageInMenu();
              }}
              listType='picture'
              onChange={(e) => handleChangeImages(e, "cover")}
              onPreview={onPreview}
              beforeUpload={beforeUpload}
              multiple={true}
              maxCount={4}
              accept='image/jpg,image/jpeg,image/png,video/mp4'
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxHeight: 10,
                }}
              >
                <InboxOutlined
                  style={{
                    color: errorSizeCover ? COLORS.danger : COLORS.primary,
                    fontSize: "2rem",
                  }}
                  className='mx-3'
                />
                <Text
                  style={{
                    fontSize: "1rem",
                    color: errorSizeCover ? COLORS.danger : COLORS.primary,
                  }}
                  className='my-font'
                >
                  اضف الصورة
                </Text>
              </div>
            </Dragger>
          </div>
          <Divider />
          <CustomButton
            type='primary'
            loading={loading}
            disabled={loading || images?.length === 0}
            text={"تنزيل الملف"}
            onClick={async () => {
              try {
                setLoading(true);
                const res = await getStateygFiles(id);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "report.pdf");
                document.body.appendChild(link);
                link.click();
              } catch (error) {
                console.log(error);
              } finally {
                setLoading(false);
              }
            }}
          />
        </Spin>
      </Content>
    </Layout>
  );
}

export default OneStrategies;
