import {
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";
import LOGO from "../../assets/images/logoNew.png";
import dayjs from "dayjs";
import openNotification from "../../Components/Notifications";
import { useNavigate } from "react-router-dom";
import { addNewStartegy } from "../../API/fetch";
import TEACEHERS from "./school_names.json";
import Statigess from "./learning_strategies.json";
const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const grades = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
];
function Startegies() {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const data = {
        ...values,
      };
      console.log(data);
      const res = await addNewStartegy(data);
      console.log(res?.data);
      navigate(`/strategies/${res?.data?.strategy?._id}`);
      openNotification({
        title: "تم",
        description: "تم اضافة الاستراتيجية بنجاح",
        type: "success",
        rtl: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Content
        style={{
          flex: 2,
          display: "flex",

          flexDirection: "column",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image preview={false} width={220} src={LOGO} />
        </div>
        <Title
          style={{
            textAlign: "center",
          }}
          level={2}
        >
          {" "}
          استراتيجيات التعلم
        </Title>
        <Divider />
        <Spin spinning={loading}>
          <Form
            name='teacher-form'
            form={form}
            preserve={false}
            className='form-container'
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              techearName: "",
              stategyName: "",
              subject: "",
              title: "",
              grade: "",
              date: null,
            }}
          >
            {() => {
              return (
                <>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "justName" })}
                        className='mb-1'
                        name='techearName'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Select className='my-font' style={{ width: "100%" }}>
                          {TEACEHERS.map((teacher) => (
                            <Option
                              className='my-font'
                              key={teacher}
                              value={teacher}
                            >
                              {teacher}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={intl.formatMessage({ id: "grade" })}
                        className='mb-1'
                        name='grade'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Select
                          className='my-font'
                          style={{ width: "100%" }}
                          placeholder={intl.formatMessage({ id: "select" })}
                          rules={[
                            {
                              required: true,
                              message: intl.formatMessage({ id: "errorname" }),
                            },
                          ]}
                        >
                          {grades.map((grade) => (
                            <Option
                              className='my-font'
                              key={grade}
                              value={grade}
                            >
                              {grade}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"المادة"}
                        className='mb-1'
                        name='subject'
                        style={{ borderRadius: 20 }}
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"موضوع الدرس"}
                        className='mb-1'
                        name='title'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <Input
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"الاستراتيجية"}
                        className='mb-1'
                        name='stategyName'
                        style={{ borderRadius: 20 }}
                      >
                        <Select className='my-font' style={{ width: "100%" }}>
                          {Statigess.map((stategy) => (
                            <Option
                              className='my-font'
                              key={stategy}
                              value={stategy}
                            >
                              {stategy}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                        label={"التاريخ"}
                        className='mb-1'
                        name='date'
                        style={{ borderRadius: 20 }}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: "errorname" }),
                          },
                        ]}
                      >
                        <DatePicker
                          className={"input"}
                          style={{ borderRadius: 5, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />

                  <CustomButton
                    type='primary'
                    htmlType='submit'
                    className='my-font mt-3'
                    style={{ width: "100%" }}
                    loading={loading}
                    text={intl.formatMessage({ id: "save" })}
                  />
                </>
              );
            }}
          </Form>
        </Spin>
      </Content>
    </Layout>
  );
}

export default Startegies;
